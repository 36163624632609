<template>
  <div class="dashboard full-height">
    <v-tabs
      v-model="tab"
      centered
      show-arrows
      :prev-icon="'mdi-arrow-left-bold-box-outline'"
      :next-icon="'mdi-arrow-right-bold-box-outline'"
      class="dashboard-tabs"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-dashboard`" to="/dashboard/home">
        <v-icon class="mr-2">dashboard</v-icon>
        {{ $t('navbar.home') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.map'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-map`" to="/dashboard/map">
        <v-icon class="mr-2">map</v-icon>
        {{ $t('map.map') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.alertDashboard'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-alert-dashboard`" to="/dashboard/alert-dashboard">
        <v-icon class="mr-2">mdi-alert</v-icon>
        {{ $t('dashboard.alerts') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.engineStateDashboard'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-engine-state-dashboard`" to="/dashboard/engine-state-dashboard">
        <v-icon class="mr-2">mdi-engine</v-icon>
        {{ $t('dashboard.engineState') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.harverst'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-harvester`" to="/dashboard/harvester">
        <v-icon class="mr-2">spa</v-icon>
        {{ $t('dashboard.harvest') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.truck'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-truck`" to="/dashboard/truck">
        <v-icon class="mr-2">commute</v-icon>
        {{ $t('dashboard.transports') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.positions'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-position`" to="/dashboard/position">
        <v-icon class="mr-2">location_on</v-icon>
        {{ $t('dashboard.positionReport') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.running.hours'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-running-hours`" to="/dashboard/running-hours">
        <v-icon class="mr-2">timer</v-icon>
        {{ $t('dashboard.runningHoursReport') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.real.speed'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-real-speed`" to="/dashboard/real-speed">
        <v-icon class="mr-2">speed</v-icon>
        {{ $t('dashboard.speedReport') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.real.state'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-current-status`" to="/dashboard/current-status">
        <v-icon class="mr-2">departure_board</v-icon>
        {{ $t('dashboard.currentStatus') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.machine.summary'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-machine-summary`" to="/dashboard/machine-summary">
        <v-icon class="mr-2">mdi-tractor</v-icon>
        {{ $t('dashboard.machineSummary') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.service.order'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-service-order`" to="/dashboard/on-board-monitor">
        <v-icon class="mr-2">tablet_mac</v-icon>
        {{ $t('dashboard.serviceOrder') }}
      </v-tab>
      <v-tab v-if="enterpriseTimeSpan && enterpriseTimeSpan.shift && enterpriseTimeSpan.enable && validatePrivilegesAnd(['dashboard.objectiveTendency'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-objective-tendency`" to="/dashboard/objective-tendency">
        <v-icon class="mr-2">tablet_mac</v-icon>
        {{ $t('dashboard.objectiveTendency') }}
      </v-tab>
      <v-tab v-if="validatePrivilegesAnd(['dashboard.efficiency'])" class="ma-0 px-1 py-2 dashboardTab" :href="`#tab-service-order`" to="/dashboard/efficiency">
        <v-icon class="mr-2">mdi mdi-timer-cog-outline</v-icon>
        {{ $t('dashboard.efficiency') }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<style scoped>
  .dashboard-tabs  {
    height: auto !important;
  }
  .dashboard .v-tabs-items {
    height: calc(100% - 72px)  !important;
  }
  .dashboard >>>.map-buttons-padding{
    padding-right: 18px !important;
  }
  #setting-btn{
    z-index: 1;
  }
  #tab-real-speed{
    height: calc(100% - 60px) !important;
  }
  .v-application .mr-2 {
    margin-right: 0px !important;
  }
  .dashboardTab {
    padding-right: 10px !important;
  }
  #truck-report {
    height: calc(100% - 40px) !important;
  }
  #table-container{
    height: calc(100% - 72px) !important;
  }
  .current-status-report{
    height: calc(100% - 43px) !important;
  }
  .dashboard >>> .origin-table-container {
    height: calc(100% + 5px) !important;
  }
  .dashboard >>> .harvester-summary-report {
    height: calc(100% - 40px) !important;
  }
</style>

<script>
import { loginService } from '@/business/loginService'
export default {
  name: 'DashboardView',
  components: {
  },
  data: () => ({
    tab: `tab-harvester`,
    enterpriseTimeSpan: {}
  }),
  computed: {
  },
  async mounted () {
    this.enterpriseTimeSpan = await this.getEnterpriseTimeSpan();
    const meta = this.$route.meta
    if (meta.tab) this.tab = meta.tab
    else this.tab = 'tab-harvester'
  },
  methods: {
    async getEnterpriseTimeSpan () {
    const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
    if (enterpriseId) {
      await loginService.refreshAndUpdateUserData(enterpriseId, this.$i18n)
    }
    return JSON.parse(localStorage.getItem("enterpriseTimeSpan"));
  }
  },
  watch: {
    tab () {
      const positionReportRef = this.$refs['position']
      const positionDetailRef = this.$refs['detail']
      const currentStateMap = this.$refs['current-state-map']
      if (this.tab === 'tab-7') {
        if (this.selectedTabCurrentState === 'tab-map' && currentStateMap) currentStateMap.change()
        if (positionReportRef) positionReportRef.loadingSpinner = false
        if (positionDetailRef) positionDetailRef.loadingSpinner = false
      } else if (this.tab === 'tab-6') {
        if (positionReportRef && positionDetailRef) {
          if (this.selectedTabPosition === 'tab-position') {
            positionDetailRef.loadingSpinner = false
            positionReportRef.loadingSpinner = true
            positionReportRef.$refs['PositionReportMapComponent'].change()
          } else if (this.selectedTabPosition === 'tab-position-detail') {
            positionDetailRef.loadingSpinner = true
            positionReportRef.loadingSpinner = false
            positionDetailRef.$refs['PositionDetailMapComponent'].change()
          }
        }
      }
    }
  }
}
</script>
